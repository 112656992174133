import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Introduction from "sections/Introduction.js";
import NewEntrepreneurs from "sections/NewEntrepreneurs.js";
import UserRoles from "sections/UserRoles.js";
import Rfq from "sections/RFQ.js";
import bannerShip from "images/sections/banner-ship.svg";
import Signup from "sections/Signup.js";
import Navbar from "sections/Navbar.js";
import Header from "sections/header.js";
import Footer from "sections/Footer.js";
import ExporterModal from "sections/ExporterModal";
import { Helmet } from "react-helmet-async";

const headerData = [
  {
    heading: "Launch Your Export Venture Today!",
    description:
      "Expert Business Setup Assistance for Aspiring Exporters. Start Your Journey Today!",
    primaryButtonText: "Request for Demo",
    subHeading: "Expert Guidance",
    image: bannerShip,
  },
];

export default () => {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>
          Find top rice exporters & mills in India for your EXIM business!
        </title>
        <meta
          name="description"
          content="Connect with the best rice exporters and top rice mills in India for premium quality and reliable trade solutions. Simplify your EXIM business with trusted partners!"
        />
        <meta
          name="keywords"
          content="Find Rice Buyers/Importers,rice exporting companies in india,Top Rice Exporters in india,rice mills in india,Parboiled Rice Exporters in India,basmati rice and non basmati Rice manufacturers in india,basmati rice and non basmati  Rice Mills in india"
        />
        <link rel="canonical" href="/exporter"/>
      </Helmet>
      <Navbar />
      <Header
        data={headerData}
        isExporterModal={true}
        openExporterModal={() => {
          setOpenModal(true);
        }}
        closeExporterModal={() => {
          setOpenModal(false);
        }}
      />
      <NewEntrepreneurs />
      <Signup />
      <Footer />
      {openModal ? (
        <ExporterModal
          modalIsOpen={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      ) : null}
    </AnimationRevealPage>
  );
};
