import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import bannerbg from "images/sections/banner-bg.svg";
import { ReactComponent as ArrowRight } from "images/arrow-right-icon.svg";
import { ReactComponent as ButtonPrefix } from "images/button-prefix.svg";
import { ReactComponent as ArrowLeftIcon } from "images/left-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "images/right-arrow.svg";
import "slick-carousel/slick/slick.css";
import DemoModal from "./DemoModal";

const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug lg:text-[64px] font-bold`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 flex inline-block tracking-wide w-[fit-content] items-center`;

const Container = styled.div`
  ${tw`flex relative mx-2 mt-2 bg-center bg-cover bg-no-repeat min-h-[80vh] flex items-center`}
  background-image: url('${bannerbg}');
`;

const Content = tw.div`w-full`;
const TestimonialSliderContainer = tw.div``;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw='right-0'>
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw='left-0'>
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

export default ({
  data,
  openExporterModal,
  closeExporterModal,
  isExporterModal = false,
  ctaLink,
}) => {
  const buttonRoundedCss = tw`rounded-full`;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    if (isExporterModal) {
      openExporterModal();
      return;
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    if (isExporterModal) {
      closeExporterModal();
      return;
    }
    setIsOpen(false);
  };

  return (
    <Container>
      <Content>
        <TestimonialSliderContainer>
          <TestimonialSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
          >
            {data?.map((data, index) => (
              <Testimonial key={index}>
                <TextContainer>
                  <div
                    style={{
                      padding: "8px 18px",
                      borderRadius: 4,
                      background: "#EDEFFF",
                      width: "fit-content",
                      color: "#3C50E5",
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  >
                    {data.subHeading}
                  </div>
                  <Heading>{data.heading}</Heading>
                  <Description>{data.description}</Description>
                  <PrimaryButton
                    as='a'
                    css={buttonRoundedCss}
                    onClick={!ctaLink ? openModal : undefined}
                    href={ctaLink}
                    target={ctaLink ? '_blank' : undefined}
                  >
                    <ButtonPrefix style={{ marginRight: 10, width: 30 }} />{" "}
                    {data.primaryButtonText}
                    <ArrowRight style={{ marginLeft: 10, width: 20 }} />
                  </PrimaryButton>
                </TextContainer>
                {data.image && (
                  <ImageContainer>
                    <img src={data.image} alt='...' />
                  </ImageContainer>
                )}
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      <DemoModal modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </Container>
  );
};
