import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navbar from "sections/Navbar.js";
import Contact from "sections/ContactForm.js";
import ContactDetails from "sections/ContactDetails.js";
import Footer from "sections/Footer.js";
import Signup from 'sections/Signup.js';
import { Helmet } from "react-helmet-async";


export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Contact my EXIM business for trusted trade solutions today!</title>
        <meta name="description" content="Contact my EXIM business for trusted trade solutions today!"/>
        <link rel="canonical" href="/contact"/>
      </Helmet>
      <Navbar/>
      <Contact/>
      <ContactDetails/>
      <Signup/>
      <Footer />
    </AnimationRevealPage>
  );
}
