import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Introduction from "sections/Introduction.js";
import UserRoles from "sections/UserRoles.js";
import Rfq from "sections/RFQ.js";
import Signup from "sections/Signup.js";
import Navbar from "sections/Navbar.js";
import Header from "sections/header.js";
import Footer from "sections/Footer.js";
import bannerHomeShip from "images/sections/banner-home-ship.svg";
import { Helmet } from "react-helmet-async";


const headerData = [
  {
    heading: "Empowering Your Global Trade Journey",
    description: "No Subscription, No Commission, No Mediators!",
    image: bannerHomeShip,
    primaryButtonText: "Sign Up for Free",
    subHeading: "Your Gateway to Global Trade",
  },
];

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>
          Find top rice exporters & freight forwarders in India for your EXIM
          needs!
        </title>
        <meta
          name="description"
          content="Discover the best rice exporters and reliable freight forwarders in India for seamless global trade. Simplify your EXIM business with trusted partners today!"
        />
        <meta
          name="keywords"
          content="rice exporters in india,basmati Rice and non basmati rice exporters in india,rice suppliers in india,rice manufacturers in india,Book Containers Online for Export from India,Top Freight Forwarders in India,Best Customs House Agent in India,Top Shipping Lines in India"
        />
        <link rel="canonical" href="/"/>
      </Helmet>
      <Navbar />
      <Header
        data={headerData}
        ctaLink="https://app.myeximbusiness.com/signup"
      />
      <Introduction />
      <UserRoles />
      <Rfq />
      <Signup />
      <Footer />
    </AnimationRevealPage>
  );
};
