import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navbar from "sections/Navbar.js";
import FeatureRoles from "sections/FeatureRoles.js";

import Footer from "sections/Footer.js";
import Header from "sections/header.js";
import { Helmet } from "react-helmet-async";


const headerData = [
  {
    heading: "Effortless Collaboration,Seamless Trade",
    description:
      "Explore MyEXIM's Features for Effortless Global Collaboration!",
    primaryButtonText: "Request for Demo",
    subHeading: "Our Features",
  },
];

export default () => {
  return (
    <AnimationRevealPage>
      <Helmet>
        <title>Explore EXIM business features for seamless global trade!</title>
        <meta name="description" content="Explore my EXIM business features: connect with top exporters, freight forwarders, and manufacturers. Streamline trade with trusted tools and partners!"/>
        <link rel="canonical" href="/features"/>
      </Helmet>
      <Navbar />
      <Header data={headerData} />
      <FeatureRoles />
      <Footer />
    </AnimationRevealPage>
  );
};
